*{
    margin: 0;
}

h1{
  font-size: 28px;  
  text-align: center;
  margin: 0;
}

.action-cards{
    position: relative;
    z-index: 100;
    height: 500px;
    display: flex;
    min-width: 230px;
    flex-direction: column;
    justify-content: space-between;
}

.action-card{
    text-align: center;
    transform: translate(1px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 230px;
    padding: 10px;
    height: 200px;
    background-color: rgb(255, 255, 255);
    transition: .2s linear;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}

.action-card h1{
    margin-bottom: 10px;
}

.action-card:hover{
    transform: translate(240px);
}