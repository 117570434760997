body{
    margin: 0;
    background: url(https://phonoteka.org/uploads/posts/2022-02/1643928296_1-phonoteka-org-p-temnii-beton-fon-1.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100vw 100vh;
    font-family: 'Gilroy-Medium', sans-serif;
    font-size: 18px;
}

.header{
    margin-bottom: 25px;
    width: 98.45vw;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    color: white;
    font-family: 'Gilroy-Medium', sans-serif;
}

.logo{
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    font-family: 'Gilroy-Bold', sans-serif;
    font-size: 56px;
    margin-left: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.bunker{
    width: 300px;
}

.disaster{
    width: 800px;
    font-family: 'Gilroy-Medium', sans-serif;
}

.info{
    position: relative;
    left: 100px;
    width: 300px;
}

.info li:first-child,
.bunker li:first-child{
    position: relative;
    right: 20px;
    list-style: none;
}