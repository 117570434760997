.specifications,
.emptyCard{
    position: relative;
    min-width: 230px;
    height: 760px;
    line-height: 30px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    transition: .2s linear;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    z-index: 100;
}

.specifications:hover,
.emptyCard:hover{
    transform: translate(-240px);
}

.specifications__tittle{
    text-align: center;
    font-size: 22px;
    font-family: 'Gilroy-Bold', sans-serif;
}

.specifications__elem{
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.specifications__elem:hover{
    font-family: 'Gilroy-Bold', sans-serif;
    cursor: pointer;
}

.specifications__input{
    font-size: 18px;
}

.cardButton{
    padding: 10px 20px;
    font-size: 16px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    margin-top: 10px;
    border-radius: 10px;
    border: 1px solid rgb(0, 0, 0);
    transition: 0,2s linear;
}

.cardButton:hover{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
}