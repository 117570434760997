video {
    width: 430px;
    height: auto;
    max-height: 440px;
}

.wrapper{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.webcams{
    min-width: 100vw;
    max-width: 100vw;
    height: 78vh;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.cam{
    position: relative;
    z-index: 100;
    font-size: 18px;
    align-items: flex-end;
    justify-content: space-between;
    overflow: hidden;
}

.cam,
.exiled-player,
.player-info{
    z-index: 1;
    width: 427px;
    margin: 5px;
    height: 240px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid rgb(0, 0, 0);
    display: flex;
    color: white;
    position: relative;
}

.cam__image {
    position: absolute;
    bottom: -4px;
    z-index: 1;
}

.mirroring-block{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.isMirrored{
    transform: scale(-1, 1);
}

.custom-checkbox{
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.custom-checkbox+label{
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.custom-checkbox+label::before{
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #000000;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    cursor: pointer;
    margin-left: 10px;
}

.custom-checkbox:checked+label:before{
    background-color: rgb(46, 209, 21);
}

.cam__img{
    width: 440px;
    height: 240px;
}

.exiled-player h1{
    position: relative;
    z-index: 100;
}

.exiled-player{
    background: url("https://swoldemi.me/VR-Project-1/images/metal-door.png");
    background-size: cover;
    justify-content: center;
    align-items: center;
    font-family: 'Gilroy-Light', sans-serif;
}

.exiled-player::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.cam__votes{
    position: absolute;
    width: 150px;
    text-align: right;
    padding: 5px;
    height: 15px;
    top: 7px;
    right: 0px;
    transition: .3s;
    color: black;
    font-size: 18px;
    transform: translate(160px);
    background: linear-gradient(-90deg, rgb(249, 129, 44), rgba(255, 255, 255, 0));
}

.active{
    transform: translate(0px);
}

.cam__left{
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 97%;
    position: relative;
    z-index: 10;
}

.cam__right{
    margin: 5px;
    text-align: right;
    width: 180px;
    position: relative;
    z-index: 10;
}

.cam__feature{
    margin-top: 7px;
    font-size: 16px;
}

.cam__gender-age{
    display: flex;
}

.cam__gender-age div{
    background-color: rgb(249, 129, 44);
    color: black;
    padding: 2px;
    margin-right: 8px;
    font-weight: 700;
}

.cam__name{
    margin: 5px 0px;
    min-width: 250px;
    padding-left: 5px;
    font-size: 20px;
    color:rgb(0, 0, 0);
    background-color: rgb(249, 129, 44);
    background: linear-gradient(90deg, rgb(249, 129, 44), rgba(255, 255, 255, 0));
}
