*{
    margin: 0;
    padding: 0;
}

p{
    margin-bottom: 10px;
}

.main{
    width: 100%;
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 77vh;
}

.players{
    display: flex;
    border-radius: 20px;
    justify-content: space-around;
    align-content: space-between;
    flex-wrap: wrap;
    width: 600px;
    height: 750px;
    background-color: #fff;;
    padding: 25px;
}

.player-link{
    font-size: 18px;
    text-align: center;
    padding: 5px;
    border: 1px solid black;
    border-radius: 15px;
    width: 170px;
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: rgb(0, 0, 0);
    box-sizing: border-box;
}

.player-link:hover{
    border: 3px solid black;
}

.player-link svg{
    width: 200px;
}

.rules{
    width: 500px;
    height: 700px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    margin-right: 40px;
    border-radius: 20px;
}

.rules-title{
    font-size: 34px;
    margin: 10px 0px;
}

.settings-link{
    position: absolute;
    width: 50px;
    bottom: 50px;
    right: 50px;
    height: 50px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.settings-link img {
    width: 50px;
}

